<template>
    <div>
        <b-sidebar id="add_starter_inventory" width="50rem" backdrop aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="سند قبض" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{lang.store_settlement}}</span>
                </div>
                <div @click="hide" id="payHide" style="wodth:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{lang.close}}</span>
                </div>
            </div>
            <v-divider></v-divider>

            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="4" sm="12">
                        <label for="input-live">{{lang.date}}</label>
                        <div class="flexTay">
                            <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.day" :options="$store.state.daysList"/>
                            <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.month" :options="$store.state.monthsList" />
                            <b-form-select class="inborder text-center" style="width:60%;background:#efefef;" v-model="sd.year" :options="$store.state.yearList" />
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label for="input-live">{{lang.chose_store}}</label>
                        <div class="flexTay">
                            <b-form-select class="inborder text-center selborder" style="" v-model="store_id" :options="storeList"/>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <b-table-simple>
                            <thead>
                                <th class="backBlack text-center" style="width:100px;">{{ lang.item_code }}</th>
                                <th class="backBlack text-center">{{ lang.item_name }}</th>
                                <th class="backBlack text-center" style="width:100px;">{{ lang.qty }}</th>
                                <th class="backBlue text-center" style="width:100px;" v-if="status==1">{{ lang.action }}</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in item_rows" :key="index">
                                    <td class="text-center">
                                        <b-form-input v-model="item_rows[index].item_code" class="text-center inborder" @change="getProduct(item.item_code,index)" />
                                    </td>
                                    <td class="text-center">
                                        {{ item.item_name }}
                                    </td>
                                    <td class="text-center">
                                        <b-form-input v-model="item_rows[index].qty" class="text-center inborder"  />
                                    </td>
                                    <td class="text-center backRed" style="color:#FFF;cursor:pointer;" @click="removeItem(index)" v-if="status == 1">
                                        {{ lang.delete }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot v-if="status == 1">
                                <tr>
                                    <th class="text-center">
                                        <b-form-input v-model="newrow.item_code" class="text-center inborder" @change="getProduct(newrow.item_code,0)" />
                                    </th>
                                    <th class="text-center">
                                        {{ newrow.item_name }}
                                    </th>
                                    <th class="text-center">
                                        <b-form-input v-model="newrow.qty" class="text-center inborder" />
                                    </th>
                                    <th class="text-center backBlue" style="color:#FFF;cursor:pointer;" @click="addItem()">
                                        {{ lang.add }}
                                    </th>
                                </tr>
                            </tfoot>
                        </b-table-simple>
                    </v-col> 
                </v-row>
            </div>
        </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" id="adVouBTN" @click="postInv()" variant="success" class="ma-2" style="width:150px;background:blue !important;" v-if="status == 1 && id != 0">{{lang.postInv}}</b-button>
                    <b-button type="button" id="adVouBTN" @click="addStartInv()" variant="success" class="ma-2" style="width:100px;" v-if="status == 1">{{lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
        
    </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';

export default{
    data() {
        return {
            id: 0,
            status: 1,
            item_rows: [],
            store_id: 1,
            newrow: {
                id: 0,
                item_id: '',
                item_code: '',
                item_name: '',
                qty: 0,
            },
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            stores: []
        }
    },
    computed: {
        lang: function()
        {
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }
            else{
                return this.$store.state.lang.ar;
            }
        },
        storeList: function(){
            return this.stores.map((item) => {
                return {text: item[`name_${this.lang.langname}`], value: item.id};
            });
        },
        date: function(){
            return  `${this.sd.year}-${('0'+this.sd.month).slice(-2)}-${('0'+this.sd.day).slice(-2)}`;
        }
    },
    created() {
        // this.received_total = this.cAmount;
        // setTimeout(() => {this.getPyamentType()},2000);
        this.getStores();
    },
    methods: {
        postInv()
        {
            let message = "انت الان تقوم بترحيل التسوية للمخزون .. لن تستطيع التراجع عن هذه الخطوة هل انت متأكد؟";
            this.$snotify.error(message, this.lang.alert, {
                timeout: 1000000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: SnotifyPosition.centerCenter,
                buttons: [
                    {
                        text: "مراجعة التسوية",
                        class: 'backBlue', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id);
                            return false; 
                        } 
                    },
                    {
                        text: "الغاء الامر", 
                        class: 'backRed', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id);
                            document.getElementById('payHide').click();
                            return false;
                        } 
                    },
                    {
                        text: "ترحيل الرصيد", 
                        class: 'backGreen', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                            this.doPostInv();
                        } 
                    },
                ]
            });
        },
        doPostInv(){
            const post = new FormData();
            post.append("type",'postStoreSettlementItmes');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.id);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    document.getElementById('payHide').click();
                    this.$parent.getStarterInv();
                }
            );
        },
        getStores(){
            const post = new FormData();
            post.append("type",'getStores');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',0);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.stores = res.results.data;
                }
            );
        },
        addStartInv()
        {
            document.getElementById('adVouBTN').disabled = true;
            const post = new FormData();
            post.append("type",'addSettlementInventory');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[item_rows]',JSON.stringify(this.item_rows));
            post.append('data[starter_date]',this.date);
            post.append('data[store_id]',this.store_id);
            post.append('data[id]',this.id);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    document.getElementById('adVouBTN').disabled = false;
                    const res = response.data;
                    this.$snotify.success(this.lang.added_success, this.lang.success, {
                        position: SnotifyPosition.rightTop,
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true
                    });
                    document.getElementById('payHide').click();
                    this.$parent.getStarterInv();
                }
            );
        },
        addItem(){
            if(this.newrow.item_code == '' || this.newrow.qty == 0){
                this.$snotify.error('لا يمكن اضافة صنف فارغ', this.lang.error, {
                    position: SnotifyPosition.rightTop,
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true
                });
                return false;
            }
            this.item_rows.push(this.newrow);
            this.newrow = {
                id: 0,
                item_id: '',
                item_code: '',
                item_name: '',
                qty: 0,
            };
        },
        removeItem(index){
            this.item_rows.splice(index,1);
        },
        getProduct(code,index)
        {
            if(code == ''){
                return false;
            }
            const post = new FormData();
            post.append("type",'getProducts');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[item_number]',code);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res.results.data.length == 0){
                        this.$snotify.error('الصنف غير موجود في المخزون يرجى اضافة الصنف اولا', this.lang.error, {
                            position: SnotifyPosition.rightTop,
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true
                        });
                        return false;
                    }
                    if(index == 0){
                        this.newrow.item_name = res.results.data[0][`item_name${this.lang.langname}`];
                        this.newrow.item_id = res.results.data[0].id;
                        this.newrow.qty = 1;
                    }else{
                        this.item_rows[index][`item_name`] = res.results.data[0][`item_name${this.lang.langname}`];
                        this.item_rows[index][`item_id`] = res.results.data[0].id;
                        
                    }
                }
            );
        },
        getStarterInventory()
        {
            const post = new FormData();
            post.append("type",'getStoreSettlementItmes');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.id);
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.item_rows = res.results.data[0].rows;
                    this.status = res.results.data[0].status;
                    this.store_id = res.results.data[0].store_id;
                    this.sd = {
                        day: (new Date(res.results.data[0].starter_date)).getDate(),
                        month: (new Date(res.results.data[0].starter_date)).getMonth() + 1,
                        year: (new Date(res.results.data[0].starter_date)).getFullYear(),
                    };
                }
            );
        }
        
    },
}
</script>